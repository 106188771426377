@font-face {
    font-family: 'Geometria';
    src: url('Geometria.eot');
    src: local('Geometria'),
        url('Geometria.eot?#iefix') format('embedded-opentype'),
        url('Geometria.woff') format('woff'),
        url('Geometria.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('Geometria-Bold.eot');
    src: local('Geometria Bold'), local('Geometria-Bold'),
        url('Geometria-Bold.eot?#iefix') format('embedded-opentype'),
        url('Geometria-Bold.woff') format('woff'),
        url('Geometria-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}