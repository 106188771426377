@import "../fonts/Geometria/stylesheet.css";
@import "antd-custom-styles.css";

:root {
    --container-width: 1920px;
}

* {
    font-family: Geometria, sans-serif;
}

body {
    -webkit-font-smoothing: antialiased;
    background-color: #f9f9fa;
    overflow-x: hidden;
}

.main-layout {
    min-height: 100vh;
}

.main-layout__content {
    position: relative;
    display: flex;
}

.header__wrap {
    display: flex;
    align-items: center;
}

.header__wrap .logo {
    margin-right: 25px;
    line-height: 1;
}

.cursor-pointer{
    cursor: pointer;
}

.d-flex{
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.align-center {
    align-items: center;
}

.flex-grow {
    flex-grow: 1;
}

.gap-1 {
    gap: 0.5rem;
}

.container {
    max-width: var(--container-width);
    width: 100%;
    margin: 0 auto;
    padding: 0px 24px;
}

.my-1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mt-3{
    margin-top: 1rem;
}

.text-right {
    text-align: right;
}
.text-center{
    text-align: center;
}

.w50 {
    width: 125px;
}

.w10 {
    width: 10px;
}

.h-full {
    height: 100%;
}

.suspense-router-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.client-routers-table__row  {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table-link:hover {
    text-decoration: underline;
}