.ant-btn a {
    /* transition: none; */
    display: block;
}

/* .ant-tree-list-scrollbar.ant-tree-list-scrollbar-vertical{
    visibility: visible !important;
} */

.main-navbar .ant-menu-submenu-horizontal .ant-menu-submenu-arrow{
    display: block;
}

.main-navbar .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow {
    transform: translateY(-50%) rotate(90deg) !important;
    inset-inline-end: -16px !important;
}

.theme-switcher .anticon {
    vertical-align: middle;
    font-size: 14px;
}