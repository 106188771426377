.login-page {
    height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.login-page__header {
    height: 100px;
    display: flex;
    align-items: center;
}

.login-page__main {
    flex-grow: 1;
    background: url("../../../assets/images/login-page2.webp") 100% bottom/contain no-repeat;
}

.login-page__block {
    max-width: 710px;
    margin-top: 180px;
}

.login-page__title {
    font-weight: 700;
    font-size: 48px;
    text-shadow: 2px 2px 9px rgba(150, 150, 150, 1);
}

.login-page__text {
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
    text-shadow: 2px 2px 9px rgba(150, 150, 150, 1);
}

@media (max-width: 1024px) {
    .login-page__block {
        margin-top: 50px;
    }
    .login-page__title {
        font-size: 40px;
        padding: 15px;
        border-radius: 3px;
    }
    .login-page__text {
        padding: 15px;
        border-radius: 3px;
    }
}

@media (max-width: 640px) {
    .login-page__main{
        padding: 0 10px;
    }

    .login-page__title {
        font-size: 28px;
    }
}
